import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Mousewheel } from 'swiper';
import moment from 'moment';
import 'moment-timezone';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import styled from 'styled-components';
import checkYes from '../styles/images/CheckYes.svg';
import checkNo from '../styles/images/CheckNo.svg';
import { eventArr, timeZoneArray, dotsArr } from '../constants/index'

SwiperCore.use([Mousewheel]);

const ProgressBar = styled.div`
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  align-items: center;
  width: 100%;
  height: 3px;
  position: relative;
  margin: auto 1rem;
`;

const ProgressDots = styled.div`
  display: ${(props) => props.checked};
  height: 1px;
  width: 1px;
  background-color: var(--alto);
  border-radius: 50%;
  margin: auto 0.188rem;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
`;

const ProgressLine = styled.div`
  width: ${(props) => props.completed};
  background-color: var(--alto);
  height: 1px;
  border-radius: 24px;
  position: absolute;
  z-index: 5;
`;

function EventTimeline() {
  const normalDate = (date) => moment(date).format('MMM YYYY');
  const dateNow = moment().valueOf();
  const calculatedData = timeZoneArray
    .sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
    .map((el, index) => {
      const isChecked = dateNow > moment(el.date).valueOf();
      const nextDate = eventArr?.[index + 1]?.date;
      const isNextDateChecked = dateNow > moment(nextDate).valueOf();
      if (!isNextDateChecked && nextDate) {
        const fullProgress =
          moment(nextDate).valueOf() - moment(el.date).valueOf();
        return {
          ...el,
          isChecked,
          lastEvent: 'flex',
          progress: `${Math.round(
            ((dateNow - moment(el.date).valueOf()) * 100) / fullProgress
          )}%`,
        };
      } else {
        return {
          ...el,
          isChecked,
          lastEvent: nextDate ? 'flex' : 'none',
          progress: isChecked ? '100%' : '0',
        };
      }
    });

  const currentIndex = calculatedData.findIndex((el) => !el.isChecked);

  return (
    <div className='event-timeline-slider'>
      <div className='event-timeline-block'>
        <Swiper
          freeMode
          mousewheel={{ eventsTarget: 'container' }}
          slidesPerView={'auto'}
          initialSlide={currentIndex - 1}>
          {calculatedData.map((el, index, arr) => {
            return (
              <SwiperSlide key={el.id}>
                <div className='event-timeline-block-body'>
                  <div className='progress-bar'>
                    <div className='event-timeline-slider-information'>
                      <h5 className='event-timeline-title'>{el.title}</h5>
                      <p className='event-timeline-text'>{el.label}</p>
                      <div className='event-timeline-date'>
                        <p>{normalDate(el.date)}</p>
                      </div>
                    </div>
                    {el.isChecked ? (
                      <img src={checkYes} alt='Check' />
                    ) : (
                      <img src={checkNo} alt='Check' />
                    )}
                    {arr.length - 1 !== index && (
                      <ProgressBar>
                        {dotsArr.map((item) => (
                          <ProgressDots key={item} checked={el.lastEvent} />
                        ))}
                        <ProgressLine completed={el.progress} />
                      </ProgressBar>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default EventTimeline;
