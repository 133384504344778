import moment from 'moment';
import 'moment-timezone';

export const eventArr = [
  {
    id: 1,
    title: 'Genesis',
    label: 'Solana meet-up and The First Boca Chica Moon Announcement',
    date: '2021-06-01',
  },
  {
    id: 2,
    title: 'Partners Onboarding Spree Has Begun',
    label: 'Genesis Block Ventures joins Boca Chica and becomes the very first seed investor',
    date: '2021-06-17',
  },
  {
    id: 3,
    title: 'The Big Guns are Out',
    label: 'KuCoin Labs joins Boca Chica as the very first Exchange Partner',
    date: '2021-06-22',
  },
  {
    id: 4,
    title: 'Validators - Hurry UP!',
    label: 'Validators can submit the form to become whitelisted',
    date: '2021-07-16',
  },
  {
    id: 5,
    title: 'Test Sale or HAPI Tokens for CHEAP',
    label: 'Unveiling of the Boca Chica Moon Edition and Test Flight',
    date: '2021-08-23',
  },
  {
    id: 6,
    title: 'How I Met Your Mother',
    label: 'The very first IDO on Boca Chica Announced - Allbridge',
    date: '2021-09-06',
  },
  {
    id: 7,
    title: 'How to Invest for Dummies',
    label: 'The very first IDO - Allbridge. 2 000 000$ in HAPI raised; more than 300x oversubscribed',
    date: '2021-09-21',
  },
  {
    id: 8,
    title: 'We are Going to MARS',
    label: 'Unveiling of Boca Chica Mars Edition. The Prime Launchpad for NEAR Blockchain',
    date: '2021-09-29',
  },
  {
    id: 9,
    title: 'Why so SECRETIVE?',
    label: 'Secret project announcement and astounding HAPI pool.',
    date: '2021-10-08',
  },
  {
    id: 10,
    title: 'At the Gate of Success',
    label: 'Second Exchange Partnership - Gate.io joins Boca Chica',
    date: '2021-10-18',
  },
  {
    id: 11,
    title: 'Consign These HAPIs to OBLIVION',
    label: 'More than 270 000$ worth of HAPI burned after successful first round of fundraising for the secret project',
    date: '2021-10-21',
  },
  {
    id: 12,
    title: 'Even Bigger',
    label: 'MEXC joins Boca Chica and becomes the third exchange to join.',
    date: '2021-10-22',
  },
  {
    id: 13,
    title: 'To Mars in Full-Swing',
    label: 'First IDO for Mars. Aurora will hold its IDO on Boca Chica Mars Edition',
    date: '2021-10-27',
  },
  {
    id: 14,
    title: 'Mars Launch',
    label: 'Aurora IDO - Launch',
    date: '2021-11-11',
  },
  {
    id: 15,
    title: 'Storing Data Anew',
    label: 'Machina - sharded data storage',
    date: '2021-12-30',
  },
];

export const timeZoneArray = eventArr.map((el) => {
  const date = moment.tz(el.date, moment.tz.guess()).format();
  return {
    ...el,
    date,
  };
});

export const dotsArr = [...Array(15).keys()];

export const btnArray = [
  {
    title: 'To the Moon',
    href: 'https://moon.bocachica.io/',
  },
  {
    title: 'To the Mars',
    href: 'https://mars.bocachica.io/',
  },
  {
    title: 'NFT Auction',
    href: 'https://auction.bocachica.io/',
  },
];