import React from 'react';
import bocachikaLogo from '../styles/images/BocachikaLogo.svg';
import shapeIcon from '../styles/images/Shape.svg';
import logoHAPI from '../styles/images/logoHAPI.png';
import medium from '../styles/images/Medium.svg';
import telegram from '../styles/images/Telegram.svg';
import twitter from '../styles/images/Twitter.svg';
import EventTimeline from './EventTimeline';
import { btnArray } from '../constants/index'

function DefaultPage() {
  return (
    <>
      <div className='content'>
        <img
          className='bocachica-logo'
          src={bocachikaLogo}
          alt='Bocachika logo'
        />
        <div className='block-choice-platform'>
          {btnArray.map((el) => {
            return (
              <a 
                className='btn-choice-platform' 
                key={el.title} 
                href={el.href} 
                rel='noreferrer' 
              >
                <p>{el.title}</p>
                <img src={shapeIcon} alt={el.title} />
              </a>
            );
          })}
        </div>
        <div className='block-event-timeline'>
          <h1>Events Timeline</h1>
          <EventTimeline />
        </div>
      </div>
      <footer>
        <div className='logo-HAPI'>
          <a href='https://www.hapi.one/' target='_blank' rel='noreferrer'>
            <img src={logoHAPI} alt='Logo HAPI' />
          </a>
          <p>HAPI-owned</p>
        </div>
        <div className='block-social-networks'>
          <a
            href='https://medium.com/boca-chica'
            target='_blank'
            rel='noreferrer'>
            <img src={medium} alt='Medium' />
          </a>
          <a href='https://t.me/BocaChicaAnnouncement' target='_blank' rel='noreferrer'>
            <img src={telegram} alt='Telegram' />
          </a>
          <a
            href='https://twitter.com/bocachica_io'
            target='_blank'
            rel='noreferrer'>
            <img src={twitter} alt='Twitter' />
          </a>
        </div>
      </footer>
    </>
  );
}

export default DefaultPage;
